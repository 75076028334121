import {makeAutoObservable} from "mobx";

export default class AppStore {
    constructor() {
        this._settings = []
        this._localizationsItems = {
            app_view_text: "Так выглядит ваш сайт с мобильного устройства",
            nothing_is_clear:"Ничего не понятно, но очень интересно?",
            edit_mode: "Режим редактирования",
            admin_panel: "Административная панель",
            site: "Сайт",
            available_app_links: "Ваше приложение доступно по ссылкам",
            app_collect: "Ваше приложение уже собирается и будет готово в течении 3 дней",
            deleted_forever: "Данные удалятся без возможности восстановления",
            not_delete: "Не удалять",
            delete_item: "Удалить запись",
            modal_title: "Мы свяжемся с вами в ближайшее время",
            modal_description: "Мы покажем и расскажем, как всё работает и ответим на вопросы",
            your_question: "Ваш вопрос",
            valid_according_to: "Действует по",
            validity_period:"Срок действия",
            edit_product: "Редактирование товара",
            category: "Категория",
            naiming: "Название",
            enter_text:"Введите текст",
            published: "Опубликовано",
            available_for_order:"Доступен для заказа",
            product: "Товар",
            mini_image: "Картинка для предпросмотра 240х300",
            big_image: "Картинка для детального просмотра 470х845",
            upload:"Загрузить"
        }
        this._lang = localStorage.getItem('i18nextLng')
        this._languages = []
        this._userCabinet = true
        this._bonuses = false
        this._minimumOrderAmount = null
        this._minimumBonusAmount = null
        this._registerByPhone = false
        this._city = null
        this._allCities = []
        this._editMode = false
        this._mobView = false
        makeAutoObservable(this)
    }

    setMobView(mobView) {
        this._mobView = mobView
    }

    setEditMode(editMode) {
        this._editMode = editMode
    }

    setMinimumBonusAmount(minimumBonusAmount) {
        this._minimumBonusAmount = minimumBonusAmount
    }

    setAllCity(cities) {
        this._allCities = cities
    }

    setCity(city) {
        localStorage.setItem('city', JSON.stringify(city));
        this._city = city
    }

    setRegisterByPhone(registerByPhone) {
        this._registerByPhone = registerByPhone
    }

    setSettings(settings) {
        this._settings = settings
    }

    setLocalizationsItems(localizationsItems) {
        this._localizationsItems = localizationsItems
    }

    setLang(lang) {
        localStorage.setItem('i18nextLng', lang)
        this._lang = lang
    }

    setLanguages(languages) {
        this._languages = languages
    }

    setUserCabinet(userCabinet) {
        this._userCabinet = userCabinet
    }

    setBonuses(bonuses) {
        this._bonuses = bonuses
    }

    setMinimumOrderAmount(minimumOrderAmount) {
        this._minimumOrderAmount = minimumOrderAmount
    }

    get mobView() {
        return this._mobView
    }

    get minimumBonusAmount() {
        return this._minimumBonusAmount
    }

    get editMode() {
        return this._editMode
    }
    
    get city() {
        return JSON.parse(localStorage.getItem('city'));
    }

    get cityString() {
        return this._city
    }

    get allCities() {
        return this._allCities
    }
    
    get registerByPhone() {
        return this._registerByPhone
    }

    get settings() {
        return this._settings
    }

    get localizationsItems() {
        return this._localizationsItems
    }

    get lang() {
        return this._lang
    }

    get languages() {
        return this._languages
    }

    get userCabinet() {
        return this._userCabinet
    }

    get minimumOrderAmount() {
        return this._minimumOrderAmount
    }
    
    get bonuses() {
        return this._bonuses
    }

}